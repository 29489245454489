<template>
  <div class="remark-exam-user-list-layout">
    <Breadcrumb>
      <BreadcrumbItem>考试管理</BreadcrumbItem>
      <BreadcrumbItem>批阅</BreadcrumbItem>
      <BreadcrumbItem>题目列表</BreadcrumbItem>
    </Breadcrumb>
    <div class="wrapper">
      <Table :columns="columns" :data="examList">
        <template slot-scope="{ row }" slot="record-name">
          <div>{{ row.sectionName }} - {{ row.questionOrder }}</div>
        </template>
        <template slot-scope="{ row }" slot="actions">
          <JYIcon class="action-icon" href="#icon-piyue" @click="handleEnterDetail(row)" />
        </template>
        <template slot-scope="{ row, index }" slot="status">
          <JYStatus v-if="row.status === 4" :key="index" label="批阅中" color="orange" />
          <JYStatus v-if="row.status === 5" :key="index" label="已批阅" color="green" />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import JYIcon from '@components/common/JYIcon'
import JYStatus from '@components/common/JYStatus'
import ExamAPI from '@api/exam'
export default {
  name: 'RemarkExamUserList',
  components: {
    JYIcon,
    JYStatus
  },
  data() {
    return {
      columns: [
        {
          key: 'questionId',
          title: '题号',
          slot: 'record-name'
        },
        {
          key: 'studentName',
          title: '考生名称'
        },
        {
          key: 'studentId',
          title: '工号'
        },
        {
          key: 'studentEmail',
          title: '邮箱'
        },
        {
          key: 'studentPhone',
          title: '手机'
        },
        {
          key: 'status',
          title: '批阅状态',
          slot: 'status'
        },
        {
          key: 'actions',
          title: '操作',
          slot: 'actions',
          align: 'center',
          width: 100
        }
      ],
      examList: []
    }
  },
  async created() {
    const examId = this.$route.params['examId']
    const { res } = await ExamAPI.getRemarkDetailList(examId)
    this.examList = res.needToCorrectedAPQuestions
  },
  methods: {
    handleEnterDetail(row) {
      const examId = this.$route.params['examId']
      this.$router.push(`/manage/exam/remark/doremark/${examId}?questionId=${row.questionId}`)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/mixin';
.remark-exam-user-list-layout {
  text-align: left;
  padding: 20px;
}
.wrapper {
  .--mixin-shadow;
  background: white;
}
.action-icon {
  cursor: pointer;
}
</style>
